import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LayoutService {
	private navbarSubject = new Subject<boolean>();
	navbar$ = this.navbarSubject.asObservable();

	constructor() {}

	hideNavbar() {
		this.navbarSubject.next(false);
	}

	showNavbar() {
		this.navbarSubject.next(true);
	}
}
